import { parseDate } from "chrono-node";
import {
  add,
  addDays,
  addHours,
  addMinutes,
  addSeconds,
  differenceInHours,
  eachHourOfInterval,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format as formatFn,
  getDay,
  isPast,
  isSameDay,
  isThisWeek,
  isThisYear,
  isToday,
  isTomorrow,
  parse,
  startOfDay,
  startOfMonth,
  startOfTomorrow,
  startOfWeek,
} from "date-fns";
import { format as dateFnsTzFormat, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { DateTimeFormatHandler, TimeFormat, TIME_FORMAT_MAP } from "../hooks/useDateTimeFormatter";
import { AllDays, DayOfWeek, Weekdays } from "../reclaim-api/Calendars";
import { YYYYMMDD } from "../reclaim-api/types";
import { TimePolicy } from "../reclaim-api/Users";
import { LongTz, ShortTz, TimeZone } from "../types";
import { addLeadingZeroes, formatFloat } from "./numbers";
import { extantMap } from "./objects";
import { pluralize, ucfirst } from "./strings";

export const TimeFormatHours = "HH:mm:ss";

/**
 * Takes a date and format and returns a display date. If the returned display date does not
 * match the provided date after being parsed then add the year to the display string to
 * ensure accuracy.
 */
export const getFormattedDisplayDate = (
  date: Date,
  timeFormat: TimeFormat,
  format: DateTimeFormatHandler,
  dayMode?: boolean
): string => {
  // clear out seconds and millis
  date.setSeconds(0);
  date.setMilliseconds(0);

  const formatted = format(date, timeFormat);
  const formattedParsed = parseDate(formatted);

  // If the formatted string does not parse back to the original date then
  // use the precise time format with year as the formatting.
  if (formattedParsed?.getTime() !== date.getTime()) {
    return format(date, !!dayMode ? "DATE_YEAR_DISPLAY_FORMAT" : "DATE_YEAR_TIME_DISPLAY_FORMAT");
  } else {
    return formatted;
  }
};

export interface DateDisplayOptions {
  lowercase?: boolean;
  neverTomorrow?: boolean;
}

export interface SmartDateDisplayOptions extends DateDisplayOptions {
  trimMinutes?: boolean;
  trimTime?: boolean;
  forceShowDate?: boolean;
}

export interface LargestDateDisplayOptions extends DateDisplayOptions {
  keepDayMonth?: boolean;
}

export const getSmartDateDisplay = (
  date: Date,
  format: DateTimeFormatHandler,
  options?: SmartDateDisplayOptions
): string => {
  const { lowercase, trimMinutes, trimTime, neverTomorrow, forceShowDate } = options || {};
  let str = "";

  if (isToday(date))
    str = `${lowercase ? "t" : "T"}oday${trimTime ? "" : ` at ${format(date, "TIME_DISPLAY_FORMAT")}`}${
      forceShowDate ? `, ${format(date, "WEEKLESS_DATE_DISPLAY_FORMAT")}` : ""
    }`;
  else if (!neverTomorrow && isTomorrow(date))
    str = `${lowercase ? "t" : "T"}omorrow${trimTime ? "" : ` at ${format(date, "TIME_DISPLAY_FORMAT")}`}${
      forceShowDate ? `, ${format(date, "WEEKLESS_DATE_DISPLAY_FORMAT")}` : ""
    }`;
  else if (isThisWeek(date) && !isPast(date))
    if (!forceShowDate) {
      str = format(date, trimTime ? "DAY_OF_WEEK_FORMAT" : "DAY_OF_WEEK_TIME_FORMAT");
    } else {
      str = format(date, "DATE_DISPLAY_FORMAT");
    }
  else if (isThisYear(date)) str = format(date, "DATE_DISPLAY_FORMAT");
  else str = format(date, "DATE_YEAR_DISPLAY_FORMAT");

  if (trimMinutes) str = str.replace(":00", "");

  return str;
};

export const getLargestUnitDate = (
  date: Date,
  format: DateTimeFormatHandler,
  options?: LargestDateDisplayOptions
): string => {
  const { lowercase, keepDayMonth, neverTomorrow } = options || {};

  if (isToday(date)) return `${lowercase ? "t" : "T"}oday`;
  else if (!neverTomorrow && isTomorrow(date)) return `${lowercase ? "t" : "T"}omorrow`;
  else if (isThisYear(date)) return format(date, "WEEKLESS_DATE_DISPLAY_FORMAT");
  else return format(date, keepDayMonth ? "WEEKLESS_DATE_YEAR_DISPLAY_FORMAT" : "YEAR_DISPLAY_FORMAT");
};

// 2/24 10am - 1:15pm, 2/24 10am - 2/25 1:15pm
export const getDateSpanMinimalDisplay = (start: Date, end: Date, format: DateTimeFormatHandler): string => {
  return `${format(start, "SHORT_DATE_TIME_FORMAT")} - ${
    isSameDay(start, end) ? format(end, "TIME_DISPLAY_FORMAT") : format(end, "SHORT_DATE_TIME_FORMAT")
  }`;
};

export interface Duration {
  seconds: number;
  minutes: number;
  hours: number;
  days: number;
  weeks: number;
}

export const SECONDS_PER_MINUTE = 60;
export const SECONDS_PER_HOUR = SECONDS_PER_MINUTE * 60;
export const SECONDS_PER_DAY = SECONDS_PER_HOUR * 24;
export const SECONDS_PER_WEEK = SECONDS_PER_DAY * 7;
// eh, close enough
export const SECONDS_PER_YEAR = SECONDS_PER_DAY * 360;

export const MILLISECONDS_PER_SECOND = 1000;
export const MILLISECONDS_PER_MINUTE = SECONDS_PER_MINUTE * MILLISECONDS_PER_SECOND;
export const MILLISECONDS_PER_HOUR = SECONDS_PER_HOUR * MILLISECONDS_PER_SECOND;
export const MILLISECONDS_PER_DAY = SECONDS_PER_DAY * MILLISECONDS_PER_SECOND;
export const MILLISECONDS_PER_WEEK = SECONDS_PER_WEEK * MILLISECONDS_PER_SECOND;
export const MILLISECONDS_PER_YEAR = SECONDS_PER_YEAR * MILLISECONDS_PER_SECOND;

export const MINUTES_PER_HOUR = 60;

export const MINUTES_PER_CHUNK = 15;
export const SECONDS_PER_CHUNK = MINUTES_PER_CHUNK * SECONDS_PER_MINUTE;

export const HOURS_PER_DAY = 24;
export const HOURS_PER_WEEK = HOURS_PER_DAY * 7;

export const durationFn = (seconds: number): Duration => {
  const dur: Duration = {
    seconds: 0,
    minutes: 0,
    hours: 0,
    days: 0,
    weeks: 0,
  };

  dur.weeks = Math.floor(seconds / SECONDS_PER_WEEK);
  seconds = seconds % SECONDS_PER_WEEK;
  dur.days = Math.floor(seconds / SECONDS_PER_DAY);
  seconds = seconds % SECONDS_PER_DAY;
  dur.hours = Math.floor(seconds / SECONDS_PER_HOUR);
  seconds = seconds % SECONDS_PER_HOUR;
  dur.minutes = Math.floor(seconds / SECONDS_PER_MINUTE);
  seconds = seconds % SECONDS_PER_MINUTE;
  dur.seconds = Math.round(seconds);

  return dur;
};

export const getSeconds = (duration: Partial<Duration>): number => {
  let seconds = duration.seconds || 0;
  seconds += (duration.minutes || 0) * SECONDS_PER_MINUTE;
  seconds += (duration.hours || 0) * SECONDS_PER_HOUR;
  seconds += (duration.days || 0) * SECONDS_PER_DAY;
  seconds += (duration.weeks || 0) * SECONDS_PER_WEEK;

  return seconds;
};

export const getHours = (duration: Partial<Duration>): number => getSeconds(duration) / SECONDS_PER_HOUR;

export const TIME_UNITS = ["ms", "sec", "min", "hr", "day", "week", "year"] as const;
export const TIME_UNITS_REV = [...TIME_UNITS].reverse();
export type TimeUnit = typeof TIME_UNITS[number];

// TODO: I think I added this, but I can't
// remember why.  It doesn't really seem to
// do anything that the string union doesn't
// do already.
const UNITS: { [U in TimeUnit]: U } = {
  year: "year",
  week: "week",
  day: "day",
  hr: "hr",
  min: "min",
  sec: "sec",
  ms: "ms",
};

const UNIT_META: Record<TimeUnit, { msPer: number }> = {
  ms: {
    msPer: 1,
  },
  sec: {
    msPer: MILLISECONDS_PER_SECOND,
  },
  min: {
    msPer: MILLISECONDS_PER_MINUTE,
  },
  hr: {
    msPer: MILLISECONDS_PER_HOUR,
  },
  day: {
    msPer: MILLISECONDS_PER_DAY,
  },
  week: {
    msPer: MILLISECONDS_PER_WEEK,
  },
  year: {
    msPer: MILLISECONDS_PER_YEAR,
  },
};

/**
 * gets the largest unit that fits into a duration of time
 * @param durationOrSeconds the duration of time
 * @returns a unit of time
 */
export function getLargestContainedUnit(durationOrSeconds: number | Duration, noDaysOrWeeks = false): TimeUnit {
  let { minutes, hours, days, weeks } =
    typeof durationOrSeconds === "number" ? durationFn(durationOrSeconds) : durationOrSeconds;

  if (!noDaysOrWeeks && weeks) return UNITS.week;
  if (!noDaysOrWeeks && days) return UNITS.day;
  if (hours) return UNITS.hr;
  if (minutes) return UNITS.min;
  return UNITS.sec;
}

export const durationStrDecimal = (
  durationOrSeconds: number | Duration,
  options: { fallbackUnit?: TimeUnit; noDaysOrWeeks?: boolean }
): string => {
  const { fallbackUnit = "hours", noDaysOrWeeks = false } = options || {};
  let { seconds, minutes, hours, days, weeks } =
    typeof durationOrSeconds === "number" ? durationFn(durationOrSeconds) : durationOrSeconds;

  if (noDaysOrWeeks) {
    hours += getHours({ days, weeks });
    days = 0;
    weeks = 0;
  }

  if (weeks) {
    const num = weeks + getSeconds({ days, hours, minutes, seconds }) / SECONDS_PER_WEEK;
    return `${formatFloat(num, 2)} ${pluralize(num, UNITS.week)}`;
  }
  if (days) {
    const num = days + getSeconds({ hours, minutes, seconds }) / SECONDS_PER_DAY;
    return `${formatFloat(num, 2)} ${pluralize(num, UNITS.day)}`;
  }
  if (hours) {
    const num = hours + getSeconds({ minutes, seconds }) / SECONDS_PER_HOUR;
    return `${formatFloat(num, 2)} ${pluralize(num, UNITS.hr)}`;
  }
  if (minutes) {
    const num = minutes + getSeconds({ seconds }) / SECONDS_PER_MINUTE;
    return `${formatFloat(num, 2)} ${pluralize(num, UNITS.min)}`;
  }
  if (seconds) return `${seconds} ${pluralize(seconds, UNITS.sec)}`;

  return `0 ${UNITS[fallbackUnit]}s`;
};

/**
 * Breaks down seconds into durations
 * (eg. 3d 4h 29m)
 * @param seconds duration in seconds
 * @deprecated use getDurationString instead
 */
export const durationStr = (durationOrSeconds: number | Duration, noDaysOrWeeks = false): string => {
  let { seconds, minutes, hours, days, weeks } =
    typeof durationOrSeconds === "number" ? durationFn(durationOrSeconds) : durationOrSeconds;

  if (noDaysOrWeeks) {
    hours += HOURS_PER_DAY * days + HOURS_PER_WEEK * weeks;
    weeks = 0;
    days = 0;
  }

  let parts: string[] = [];

  // weeks
  if (weeks) parts.push(`${Math.floor(weeks)} ${pluralize(weeks, UNITS.week)}`);

  // days
  if (days) parts.push(`${Math.floor(days)} ${pluralize(days, UNITS.day)}`);

  // hours
  if (hours) parts.push(`${Math.floor(hours)} ${pluralize(hours, UNITS.hr)}`);

  // minutes
  if (minutes) parts.push(`${Math.floor(minutes)} ${UNITS.min}`);

  // seconds
  if (seconds) parts.push(`${Math.floor(seconds)} ${pluralize(seconds, UNITS.sec)}`);

  // join
  return parts.join(" ");
};

/**
 * Options for `getDurationString`
 */
export interface GetDurationStringOptions {
  /**
   * Units that can appear in the output string (default: all)
   */
  validUnits?: TimeUnit[];
  /**
   * Maximum number of units to use.  Picks larger units first. (default: 7)
   */
  unitCount?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
  /**
   * Slices units to one character, no plurals, and removes space between
   */
  shortUnits?: boolean;
  /**
   * No units please
   */
  noUnits?: boolean;
}

/**
 * Intended as a replacement for `durationStr` with better features for picking units.  Returns a string detailing the amount of time in a duration.
 * @param durationMs The number of milliseconds in the duration
 * @param options Options
 * @returns A string representing the duration.  IE: `"1hr 2mins"`
 */
export const getDurationString = (durationMs: number, options: GetDurationStringOptions = {}): string => {
  const {
    validUnits = ["ms", "sec", "min", "hr", "day", "week", "year"],
    unitCount = 7,
    shortUnits,
    noUnits,
  } = options;

  // this allows us to loop in order
  const mappedValid = extantMap(validUnits);
  const parts: string[] = [];

  let unitsAdded = 0;
  TIME_UNITS_REV.every((unit, i) => {
    if (!mappedValid[unit]) return true;

    const { msPer } = UNIT_META[unit];
    const wholeUnits = Math.floor(durationMs / msPer);
    if (!wholeUnits) return true;

    durationMs -= wholeUnits * msPer;

    if (!noUnits && !shortUnits) {
      parts.push(`${wholeUnits} ${pluralize(wholeUnits, unit)}`);
    } else if (!!noUnits && !shortUnits) {
      parts.push(`${wholeUnits}`);
    } else if (!!shortUnits && !noUnits) {
      parts.push(`${wholeUnits}${unit.slice(0, 1)}`);
    }

    unitsAdded++;
    return unitsAdded < unitCount;
  });

  if (!parts.length) return `< 1 ${TIME_UNITS.find((unit) => mappedValid[unit])}`;

  if (!shortUnits) {
    return parts.join(" ");
  } else {
    return parts.join("");
  }
};

/**
 * Human readable string representation of time duration between two dates
 * (eg. "3d 4h 29m")
 * @param start start Date
 * @param end end Date
 */
export const durationBetweenStr = (start: Date, end: Date) => {
  const diff = (end.getTime() - start.getTime()) / 1000;
  const duration = durationStr(Math.abs(diff));
  return diff >= 0 ? duration : `${duration} ago`;
};

export const daysAgo = (start: Date, end: Date) => {
  const diff = (end.getTime() - start.getTime()) / 1000;
  const duration = Math.floor(diff / (60 * 60 * 24));
  return duration;
};

export const yearsAgo = (start: Date, end = new Date()): number => {
  return daysAgo(start, end) / 360;
};

/**
 * Simple human readable representation of relative distance
 * @param num count
 * @param unit defaults to "days"
 * @param plural suffix to pluralize unit
 * @returns a string such as "n units ago", "in n units"
 */
export const relativeStr = (num: number, unit: string = "day", plural: string = "s") => {
  if (num >= 0) return `in ${num} ${pluralize(num, unit, plural)}`;
  return `${Math.abs(num)} ${pluralize(num, unit, plural)} ago`;
};

export const durationStrToSecs = (str: string) => {
  // const numberRe = /\d*[.,]?\d+/;
  // const timeUnitRe = /(?:m(?:in(?:ute)?)?|h(?:(?:ou)?r)?|d(?:ay)?|w(?:(?:ee)?k)?)s?/;

  // const durationRe = /(?<value>\d*[.,]?\d+)\s?(?<unit>(?:m(?:in(?:ute)?)?|h(?:(?:ou)?r)?|d(?:ay)?|w(?:(?:ee)?k)?)s?)/g
  // const durationRe = /(?<${key}amount>\d*[.,]?\d+/
  const durationRe = /(\d*[.,]?\d+)\s*?((?:m(?:in(?:ute)?)?|h(?:(?:ou)?r)?|d(?:ay)?|w(?:(?:ee)?k)?)s?)/;

  if (!durationRe.test(str)) return;

  let mins = 0;
  let sub = str;
  let matches;

  while ((matches = durationRe.exec(sub))) {
    sub = sub.replace(matches[0], "");

    const num = 1 * (matches[1] || 0);

    switch (matches[2][0]) {
      case "s":
        mins += num;
        break;
      case "m":
        mins += num * 60;
        break;
      case "h":
        mins += num * 60 * 60;
        break;
      case "d":
        mins += num * 60 * 60 * 24;
        break;
      case "w":
        mins += num * 60 * 60 * 24 * 7;
        break;
      default:
        mins += num * 60; // default to mins
        break;
    }
  }

  return mins;
};

export const containsWeekdayMatch = (str: string): boolean =>
  !!/mon|tue|wed|thu|fri|sat|sun/.test(str.toLowerCase().replace(/month/g, ""));

// FIXME (IW): Using eg `[DayOfWeek.Monday]` causes errors in tests
export const DayOfWeekIndex: Record<string, number> = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7,
};

export type WeekdayIndex = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export type TemporalPosition = "current" | "earlierToday" | "past" | "laterToday" | "future";

export const getDayOfWeekDisplay = (day: DayOfWeek) => day.charAt(0).toUpperCase() + day.slice(1).toLowerCase();

export const getTemporalPosition = (now: Date, start: Date, end: Date): TemporalPosition => {
  if (start < now && end > now) return "current";
  else if (end <= now) {
    if (isSameDay(end, now)) return "earlierToday";
    else return "past";
  } else if (start > now) {
    if (isSameDay(end, now)) return "laterToday";
    else return "future";
  } else return "current"; // this shouldn't ever be reached
};

export const OffsetDayOfWeekIndex = (startOfWeek: DayOfWeek = DayOfWeek.Monday) => {
  const offset = DayOfWeekIndex[startOfWeek]; // offset in 1-indexed list

  return Object.entries(DayOfWeekIndex).reduce((acc, [d, i]) => {
    acc[d] = ((7 + (i - offset)) % 7) + 1;
    return acc;
  }, {});
};

/**
 * Sort by days of the week (eg. "[Sunday, Tuesday, Friday, ...]" )
 */
export const byDayOfWeek = (a: DayOfWeek, b: DayOfWeek) => {
  const order = DayOfWeekIndex;
  const aIdx = order[a.toUpperCase()];
  const bIdx = order[b.toUpperCase()];
  if (aIdx === bIdx) return 0;
  else return aIdx > bIdx ? 1 : -1;
};

/**
 * Check if an array of DayOfWeek is sequential (eg. "[Tuesday, Wednesday, Thursday]")
 *
 * @param days
 * @returns true if sequential, false otherwise
 */
export const isDaysSequence = (days: DayOfWeek[]) => {
  return days.every(
    (day, idx, arr) => idx === 0 || DayOfWeekIndex[day.toUpperCase()] === DayOfWeekIndex[arr[idx - 1]] + 1
  );
};

/**
 * Changes an array of DayOfWeek values into a range string
 * @param days
 * @returns returns a range of days (ie: Mon - Fri) as a string
 */
export const daysStr = (days: DayOfWeek[]): string => {
  if (days.length === 1) return ucfirst(days[0].substr(0, 3));

  return isDaysSequence(days.sort(byDayOfWeek))
    ? `${ucfirst(days[0].substr(0, 3))} - ${ucfirst(days[days.length - 1].substr(0, 3))}`
    : days.map((d) => ucfirst(d.substr(0, 3))).join(", ");
};

/**
 * Options type for `getRelativeTimestamp`
 */
export type GetRelativeTimestampOptions = GetDurationStringOptions & {
  /**
   * Prefix to attach if the string is in the past
   */
  pastPrefix?: string;
  /**
   * Prefix to attach if the string is in the future
   */
  futurePrefix?: string;
};

/**
 * Gets a string from `getDurationString`, adding text depending on if the  duration is positive or negative.
 * @param ms The time to represent (in milliseconds)
 * @param options Options
 * @returns A string representing a relative timestamp
 */
export const getRelativeTimestamp = (ms: number, options: GetRelativeTimestampOptions = {}) => {
  let { pastPrefix = "", futurePrefix = "", ...durationOptions } = options;
  const str = getDurationString(Math.abs(ms), durationOptions);
  if (pastPrefix) pastPrefix += " ";
  if (futurePrefix) futurePrefix += " ";

  return ms > 0 ? `${futurePrefix}in ${str}` : `${pastPrefix}${str} ago`;
};

/**
 * Options type for `getAutoTimestamp`
 */
export type GetAutoTimestampOptions = {
  /**
   * The date which the timestamp should be relative to (default: now)
   */
  rel?: Date;
  /**
   * The number of milliseconds either in the future or past beyond which the date should be represented in absolute form. (default: 3 days in ms)
   */
  cutoffMs?: number;
  /**
   * Options to pass the relative formatter
   */
  relativeOptions?: GetRelativeTimestampOptions;
  /**
   * Options to pass the absolute formatter
   */
  absoluteOptions?: Parameters<typeof formatFn>[2];
  /**
   * Format to use when in absolute mode (default: `"EE, PP 'at' h:mmaaa"`)
   */
  absoluteFormat?: string;
  /**
   * Prefix to attach when using absolute mode
   */
  absolutePrefix?: string;
};

/**
 * Gets a timestamp that is relative when near, or absolute when far
 * @param date The date to represent in the timestamp
 * @param options Options
 * @returns A timestamp
 */
export const getAutoTimestamp = (date: Date, options: GetAutoTimestampOptions = {}): string => {
  let {
    absoluteFormat = TIME_FORMAT_MAP.DATE_TIME_DISPLAY_FORMAT["12h"],
    cutoffMs = 3 * MILLISECONDS_PER_DAY,
    rel = new Date(),
    relativeOptions,
    absoluteOptions,
    absolutePrefix = "",
  } = options;

  const diffMs = date.getTime() - rel.getTime();

  if (absolutePrefix) absolutePrefix += " ";

  if (Math.abs(diffMs) < cutoffMs) return getRelativeTimestamp(diffMs, relativeOptions);
  else return `${absolutePrefix}on ${formatFn(date, absoluteFormat, absoluteOptions)}`;
};

const today = new Date();
today.setHours(0, 0, 0, 0);

export function addTime(
  format: DateTimeFormatHandler,
  time: Date,
  adjustments: { hour?: number; min?: number; sec?: number },
  timeFormat?: TimeFormat
): Date;
export function addTime(
  format: DateTimeFormatHandler,
  time: string,
  adjustments: { hour?: number; min?: number; sec?: number },
  timeFormat?: TimeFormat
): string;
export function addTime(
  format: DateTimeFormatHandler,
  time: string | Date,
  adjustments: { hour?: number; min?: number; sec?: number },
  timeFormat: TimeFormat
) {
  let timeAsDate = typeof time === "string" ? parse(time, timeFormat, today) : time;

  if (adjustments.hour) {
    timeAsDate = addHours(timeAsDate, adjustments.hour);
  }

  if (adjustments.min) {
    timeAsDate = addMinutes(timeAsDate, adjustments.min);
  }

  if (adjustments.sec) {
    timeAsDate = addSeconds(timeAsDate, adjustments.sec);
  }

  return typeof time === "string" ? format(timeAsDate, timeFormat) : timeAsDate;
}

export function timeGreaterThan(
  format: DateTimeFormatHandler,
  time: string | Date | undefined,
  timeThatShouldBeLessThen: string | Date | undefined,
  byAtLeast?: { hour?: number; min?: number; sec?: number },
  timeFormat: TimeFormat = "TIME_FORMAT"
) {
  if (!time) return false;
  if (!timeThatShouldBeLessThen) return true;
  const timeAsDate = typeof time === "string" ? parse(time, timeFormat, today) : time;
  const timeThatShouldBeLessThenAsDate =
    typeof timeThatShouldBeLessThen === "string"
      ? parse(timeThatShouldBeLessThen, timeFormat, today)
      : timeThatShouldBeLessThen;
  let adjustedTimeThatShouldBeLessThenAsDate = timeThatShouldBeLessThenAsDate;

  if (byAtLeast)
    adjustedTimeThatShouldBeLessThenAsDate = addTime(format, timeThatShouldBeLessThenAsDate, byAtLeast, timeFormat);

  return timeAsDate >= adjustedTimeThatShouldBeLessThenAsDate;
}

export function isValid(d?: Date) {
  if (!d) return false;
  return Object.prototype.toString.call(d) === "[object Date]" && d instanceof Date && !isNaN(d.getTime());
}

export function getDatesArray(start: Date, end: Date) {
  for (var arr: Date[] = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
    arr.push(new Date(dt));
  }
  return arr as Date[];
}

export function localTimeToMin(localtimeString: string) {
  const parts = localtimeString.split(":"); // split it at the colons

  // Hours are worth 60 minutes.
  return +parts[0] * 60 + +parts[1];
}
export function minTolocalTime(mins: number) {
  let h = Math.floor(mins / 60);
  let m = mins % 60;

  return `${h < 10 ? "0" + h : h}:${m < 10 ? "0" + m : m}:00`;
}

/**
 * Parse a formatted date used in route params (yyyy-MM-dd)
 *
 * @param str formatted date
 * @returns date if parsable, otherwise undefined
 */
export function parseRouteDate(str?: string | null) {
  if (!str) return undefined;

  switch (str.trim()) {
    case "today":
      return startOfDay(new Date());
    case "yesterday":
      return startOfDay(
        add(new Date(), {
          days: -1,
        })
      );
    case "tomorrow":
      return startOfDay(
        add(new Date(), {
          days: 1,
        })
      );
  }

  // Try newer date format first
  try {
    const parsedDate = parse(str, "yyyy-MM-dd", new Date());
    if (!isNaN(parsedDate.getTime())) return parsedDate;
  } catch (err) {
    console.warn("Failed to parse query param date", str);
  }

  // Fallback to legacy date format
  try {
    const parsedDate = parse(str, "MM-dd-yyyy", new Date());
    if (!isNaN(parsedDate.getTime())) return parsedDate;
  } catch (err) {
    console.warn("Failed to parse legacy query param date", str);
  }

  // Give up, record an error and return undefined
  console.error("Failed to parse query param date", str);
}

export type DateRange = { start: Date; end: Date };

export function snap(date: Date, range: "CHUNK" | "DAY" | "WEEK" | "MONTH", weekStartsOn?: WeekdayIndex): DateRange {
  switch (range) {
    case "CHUNK":
      throw Error("Not implemented");
    case "DAY":
      return { start: startOfDay(date), end: endOfDay(date) };
    case "WEEK":
      return {
        start: startOfDay(startOfWeek(date, { weekStartsOn })),
        end: endOfDay(endOfWeek(date, { weekStartsOn })),
      };
    case "MONTH":
      return { start: startOfDay(startOfMonth(date)), end: endOfDay(endOfMonth(date)) };
  }
}

export function roundTimeToChunk(time: Date) {
  var timeToReturn = new Date(time);

  timeToReturn.setMilliseconds(Math.round(timeToReturn.getMilliseconds() / 1000) * 1000);
  timeToReturn.setSeconds(Math.round(timeToReturn.getSeconds() / 60) * 60);
  timeToReturn.setMinutes(Math.round(timeToReturn.getMinutes() / 15) * 15);
  return timeToReturn;
}

export function roundTimeToNextChunk(time: Date) {
  const timeToReturn = new Date(time);

  // If 8:00:xx pick 8:00:00 to match backend.
  timeToReturn.setSeconds(0, 0);
  timeToReturn.setMinutes(Math.ceil(timeToReturn.getMinutes() / 15) * 15);

  return timeToReturn;
}

// TODO (IW): This is a dup, remove in favor of `DayOfWeekIndex`
export function dayOfWeekToNumber(day: DayOfWeek) {
  return ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"].indexOf(day);
}

/**
 * Shifts date using `utcToZonedTime` into current timezone, but also makes `tz` optional so it's easy to make it optional in other utility functions
 * @param date The date to shift
 * @param tz The timezone
 * @returns A shifted date
 */
export const shiftDateToTz = (date: Date, tz?: TimeZone) => (tz ? utcToZonedTime(date, tz) : date);

/**
 * Shifts date using `zonedTimeToUtc` into current timezone, but also makes `tz` optional so it's easy to make it optional in other utility functions
 * @param date The date to shift
 * @param tz The timezone
 * @returns A shifted date
 */
export const unShiftDateToTz = (date: Date, tz?: TimeZone) => (tz ? zonedTimeToUtc(date, tz) : date);

/**
 * Gets the milliseconds since day start of the day as indicated by the hour of the day (on daylight savings day you'll lose an hour at 2AM using this function - that's by design).
 * @param date The date to read from
 * @returns milliseconds into the day
 */
export const getApparentMsSinceStartOfDay = (date: Date, tz?: TimeZone) => {
  const tzDate = shiftDateToTz(date, tz);

  return (
    tzDate.getHours() * MILLISECONDS_PER_HOUR +
    tzDate.getMinutes() * MILLISECONDS_PER_MINUTE +
    tzDate.getSeconds() * MILLISECONDS_PER_SECOND +
    tzDate.getMilliseconds()
  );
};

export const getDayOfWeekFromDate = (date: Date, tz?: TimeZone): DayOfWeek => {
  return [
    DayOfWeek.Sunday,
    DayOfWeek.Monday,
    DayOfWeek.Tuesday,
    DayOfWeek.Wednesday,
    DayOfWeek.Thursday,
    DayOfWeek.Friday,
    DayOfWeek.Saturday,
  ][getDay(shiftDateToTz(date, tz))];
};

export const localTimeToMinute = (localTime: string) => {
  const [hrs, mins] = localTime
    .trim()
    .split(":")
    .map((s) => Number.parseInt(s));
  return 60 * hrs + mins;
};

/**
 * Deserialize any of the various format date strings we use into a `Date`.
 * Except dates in route params, use {@link parseRouteDate} for that...
 *
 * @param dateString
 * @param throwOnError
 * @returns Date
 */
export function strToDate(dateString: string | null): Date;
export function strToDate(dateString?: null): undefined;
export function strToDate(dateString?: string | null): Date | undefined;
export function strToDate(dateString?: string | null) {
  if (!dateString) return;

  // Default JS parsing
  let parsedDate = new Date(dateString);

  if (!isNaN(parsedDate.getTime())) {
    return parsedDate;
  }

  // Time
  parsedDate = parse(dateString, "HH:mm:ss", new Date());

  if (!isNaN(parsedDate.getTime())) {
    return parsedDate;
  }

  // US date
  parsedDate = parse(dateString, "MM/dd/yyyy", new Date());

  if (!isNaN(parsedDate.getTime())) {
    return parsedDate;
  }

  // SQL date
  parsedDate = parse(dateString, "yyyy-MM-dd", new Date());

  if (!isNaN(parsedDate.getTime())) {
    return parsedDate;
  }

  console.error(`Error parsing string to a valid date`, dateString);
  return;
}

/**
 * Convenient inverse of `strToDate` so we don't have to think about serializing dates
 *
 * @param date
 * @returns ISO date string the backend expects
 */
export const dateToStr = (date?: Date | null) => {
  if (!date) return;
  return date.toISOString();
};

export function isRangeNow(start?: Date | string, end?: Date | string, now: Date = new Date()) {
  return (
    !!start &&
    !!end &&
    (typeof start === "string" ? (strToDate(start) as Date) : start) <= now &&
    (typeof end === "string" ? (strToDate(end) as Date) : end) >= now
  );
}

export function isRangeAllDay(start: Date, end: Date) {
  return differenceInHours(end, start) >= 24;
}

export function getDatesBetween(start: Date, end: Date) {
  const dates: Date[] = [];
  let currentDate = start;

  while (currentDate <= end) {
    dates.push(currentDate);
    currentDate = add(currentDate, { days: 1 });
  }

  return dates;
}

export const startOfDayInTz = (date: Date, tz?: TimeZone) => unShiftDateToTz(startOfDay(date), tz);

export const endOfDayInTz = (date: Date, tz?: TimeZone) => unShiftDateToTz(endOfDay(date), tz);

export const isSameDayInTz = (d1: Date, d2: Date, tz?: TimeZone): boolean =>
  startOfDayInTz(d1, tz).getTime() === startOfDayInTz(d2, tz).getTime();

export function countPerDay(items: Array<{ start: Date; end: Date }>): Record<string, number> {
  return items.reduce((acc, item) => {
    const days = getDatesBetween(item.start, item.end);

    days.forEach((day) => {
      if (!acc[day.toDateString()]) {
        acc[day.toDateString()] = 0;
      }
      ++acc[day.toDateString()];
    });

    return acc;
  }, {});
}

/**
 * Returns true if date is within 15 minutes from now or earlier.
 */
export const isDateNowOrEarlier = (date: Date): boolean => {
  return date < addMinutes(new Date(), 15);
};

export const isDateNowOrEarlierThisWeek = (date: Date): boolean => {
  return date.getTime() < addMinutes(new Date(), 15).getTime() && date.getTime() > addDays(new Date(), -7).getTime();
};

export const isDateNowish = (date: Date): boolean => {
  const now = new Date();
  return date < addMinutes(now, 15) && date > addMinutes(now, -15);
};

export const isDateToday = (date: Date): boolean => {
  const today = new Date();
  return (
    date.getDate() == today.getDate() &&
    date.getMonth() == today.getMonth() &&
    date.getFullYear() == today.getFullYear()
  );
};

export const getLatestMinuteInterval = (date: Date, interval: number = 15) => {
  let lastInterval = new Date(date.getTime());
  lastInterval.setMinutes(0, 0, 0); // Start of the hour for provided date

  const getLastInterval = () => {
    if (lastInterval.getTime() + interval * 60000 < date.getTime()) {
      lastInterval = new Date(lastInterval.getTime() + interval * 60000);
      getLastInterval();
    }
  };

  // increase by interval until closest time is reached
  getLastInterval();

  return lastInterval;
};

/**
 * Gets a `YYYYMMDD` from primitives
 * @param year The year
 * @param month The month
 * @param day The day
 * @returns A `YYYYMMDD`
 */
export const makeYyyyMmDd = (year: number, month: number, day: number) =>
  `${addLeadingZeroes(year, 4)}-${addLeadingZeroes(month, 2)}-${addLeadingZeroes(day, 2)}` as YYYYMMDD;

/**
 * Gets a `YYYYMMDD` from a `Date`
 * @param date The date
 * @returns A `YYYYMMDD`
 */
export const dateToYyyyMmDd = (date: Date) => makeYyyyMmDd(date.getFullYear(), date.getMonth() + 1, date.getDate());

/**
 * Gets the year, day, month as a number from a `YYYMMDD` date
 * @param yyyyMmDd The date to get the month from
 * @returns A tuple of year, month, day - month and day are 1-indexed (January = 1, frist day of month = 1)
 */
export const destructureYyyMmDd = (yyyyMmDd: YYYYMMDD): [year: number, month: number, day: number] => {
  const [, yearStr, monthStr, dayStr] = yyyyMmDd.match(/(\d\d\d\d)-(\d\d)-(\d\d)/) || [];
  if (!yearStr) throw new Error(`Could not find year in date: ${yyyyMmDd}`);
  if (!monthStr) throw new Error(`Could not find month in date: ${yyyyMmDd}`);
  if (!dayStr) throw new Error(`Could not find day in date: ${yyyyMmDd}`);
  const yearNum = Number(yearStr);
  const monthNum = Number(monthStr);
  const dayNum = Number(dayStr);
  if (isNaN(yearNum)) throw new Error(`Could not parse year in date: ${yyyyMmDd}`);
  if (isNaN(monthNum)) throw new Error(`Could not parse month in date: ${yyyyMmDd}`);
  if (isNaN(dayNum)) throw new Error(`Could not parse day in date: ${yyyyMmDd}`);
  return [yearNum, monthNum, dayNum];
};

/**
 * Gets a date object from a `YYYYMMDD`
 * @param yyyyMmDd The date represented in `YYYYMMDD` format
 * @returns A new date object
 */
export const yyyyMmDdToDate = (yyyyMmDd: YYYYMMDD): Date => {
  const [year, month, day] = destructureYyyMmDd(yyyyMmDd);
  return new Date(year, month - 1, day);
};

export const getTomorrowAtHour = (hour: number): Date => {
  const tomorrow = startOfTomorrow();
  tomorrow.setHours(hour, 0, 0, 0);
  return tomorrow;
};

export const getHoursOfDay = (anchor?: Date): Date[] => {
  let start = startOfDay(anchor || new Date());
  let end = endOfDay(anchor || start);

  return eachHourOfInterval({ start, end });
};

export type TzFormat = "zzz" | "zzzz";

export const formatTz = <F extends TzFormat>(timeZone: TimeZone, format: F): F extends "zzz" ? ShortTz : LongTz =>
  dateFnsTzFormat(new Date(), format, { timeZone }) as F extends "zzz" ? ShortTz : LongTz;

/**
 * Get Weekdays Starting At Index
 * @param weekday The weekday number.
 *
 * accordingly to google's settings:
 * zero = sunday
 * one = monday
 * six = saturday
 *
 * @return DayOfWeek[]
 */
export const getWeekdaysStartingAtIndex = (weekday: WeekdayIndex): DayOfWeek[] => {
  const days = [DayOfWeek.Sunday, ...Weekdays, DayOfWeek.Saturday];
  const weekSplit = days.splice(0, weekday);
  return [...days, ...weekSplit];
};

/**
 *  Get the total hours in the provided time policy
 */
export const getTotalHoursInTimePolicy = (timePolicy: TimePolicy): number => {
  let hours = 0;
  const ref = startOfDay(new Date());

  AllDays.forEach((day) => {
    timePolicy.dayHours[day]?.intervals?.forEach((i) => {
      const start = parse(i.start, TimeFormatHours, ref);
      const end = parse(i.end, TimeFormatHours, ref);

      if (!!start && !!end) {
        hours += differenceInHours(end, start);
      }
    });
  });

  return hours;
};

/**
 * Returns a formatted map of a TimePolicy:
 * { MONDAY: ["9am - 9:30am", "10:30am - 1pm", ...], ... }
 */
export const timePolicyToHourDetailDays = (
  days: DayOfWeek[],
  timePolicy: TimePolicy,
  format: DateTimeFormatHandler
): { [key in DayOfWeek]?: string[] } => {
  const data: { [key in DayOfWeek]?: string[] } = {};
  const ref = startOfDay(new Date());

  days.forEach((day) => {
    if (!!timePolicy.dayHours[day]?.intervals?.length) {
      timePolicy.dayHours[day].intervals.forEach((i) => {
        const start = parse(i.start, TimeFormatHours, ref);
        const end = parse(i.end, TimeFormatHours, ref);

        if (!!start && !!end) {
          if (!data[day]) {
            data[day] = [];
          }

          data[day]?.push(`${format(start, "TIME_DISPLAY_FORMAT")} - ${format(end, "TIME_DISPLAY_FORMAT")}`);
        }
      });
    }
  });

  return data;
};

export type DurationUnit = "min" | "hr" | "day" | "week";

export type DurationDefaultUnitOptions = [number, DurationUnit][];

const PARSE_DURATION_REGEX =
  /\[?(\d*[.,]?\d+)\s?(minute(?:s)?|min|mn|m|second(?:s)?|sec|s|h(?:ou)?r(?:s)?|h|day(?:s)?|d|w(?:ee)?k(?:s)?|w)]?/gi;

/**
 * Parses a string into milliseconds.
 * @param value The string duration
 * @returns the duration in ms
 */
export function parseDuration(value: string, defaults?: DurationDefaultUnitOptions): number | undefined {
  let stringToParse = value;

  if (!!defaults?.length) {
    stringToParse = stringToParse.trim();

    if (/^\d+$/.test(stringToParse)) {
      let def = defaults.find((d) => parseInt(stringToParse) <= d[0]);
      if (!!def) {
        stringToParse = `${stringToParse} ${def[1]}`;
      }
    }
  }

  const matches = stringToParse.matchAll(PARSE_DURATION_REGEX);
  let match = matches.next();
  let duration = 0;

  while (!match.done) {
    const num = parseFloat(match.value[1].replace(/,/g, "."));
    if (typeof num !== "number" || isNaN(num)) return;
    if (typeof num === "number" && num <= 0) return;

    const unit = match.value[2];

    switch (unit[0]) {
      case "w":
        duration += num * MILLISECONDS_PER_WEEK;
        break;
      case "d":
        duration += num * MILLISECONDS_PER_DAY;
        break;
      case "m":
        duration += num * MILLISECONDS_PER_MINUTE;
        break;
      case "s":
        duration += num * MILLISECONDS_PER_SECOND;
        break;
      case "h":
      default:
        // Assume unit is a hour
        duration += num * MILLISECONDS_PER_HOUR;
        break;
    }

    match = matches.next();
  }

  return duration > 0 ? duration : undefined;
}

export default {
  isValid,
  durationStr,
  durationBetweenStr,
  byDayOfWeek,
  timeGreaterThan,
  addTime,
};
